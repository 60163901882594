import { computed, Injectable, signal } from '@angular/core';
import { ToastConfig } from '@client-portal/models/common';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _timeoutId: any | undefined;
  private _toast = signal<ToastConfig | undefined>(undefined);

  public toast = computed(() => this._toast());

  public showToast(config: ToastConfig): void {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    this._toast.set(config);

    this._timeoutId = setTimeout(() => {
      this.hideToast();
    }, 8000);
  }

  public hideToast(): void {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    this._toast.set(undefined);
  }
}
